.trigger-container {
  background: var(--Alias-background-app-frame-layer-2);
  box-shadow: 0px 2px 8px 0px var(--Palette-transparent-black-300);
  border-radius: 8px;
}

.trigger {
  --mod-actionbutton-text-to-visual: 4px;
  --mod-actionbutton-edge-to-text: 6px;
  --mod-actionbutton-edge-to-visual: 4px;
}

.trigger-chevron-icon {
  height: 7px;
  width: 7px;
}

.trigger-container {
  sp-action-button[size='m'] {
    --mod-actionbutton-text-to-visual: 4px;
    --mod-actionbutton-edge-to-text: 6px;
    --mod-actionbutton-edge-to-visual: 4px;

    height: 32px;
    width: 44px;
  }

  sp-action-button[size='l'] {
    --mod-actionbutton-text-to-visual: 4px;
    --mod-actionbutton-edge-to-text: 6px;
    --mod-actionbutton-edge-to-visual: 4px;

    height: 40px;
    width: 54px;

    .trigger-chevron-icon {
      height: 9px;
      width: 9px;
    }
  }
}

.popover {
  position: relative;
  display: flex;
  width: 180px;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border-radius: 8px;
  background: var(--Palette-gray-25);
  box-shadow: 0px 2px 8px 0px var(--Palette-transparent-black-300);
}

.add-snapshot {
  min-height: 24px;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 12px;
  margin-right: -12px;
}

.menu-item {
  --swc-menu-width: 181px;

  position: relative;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  align-items: center;

  &:hover {
    p {
      background-color: var(--Palette-gray-100);
    }
  }

  p {
    line-height: 24px;
    margin: unset;
    padding: unset;
    padding-left: 9px;
    padding-right: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 7px;
  }
}

.popover-size-s {
  width: 213px;

  .menu-item {
    --swc-menu-width: 214px;
  }
}

.popover-size-m {
  width: 222px;

  .add-snapshot {
    min-height: 32px;
  }

  .menu-item {
    --swc-menu-width: 223px;

    p {
      line-height: 32px;
      font-size: 14px;
    }
  }
}

.popover-size-l {
  width: 236px;

  .add-snapshot {
    min-height: 40px;
  }

  .menu-item {
    --swc-menu-width: 237px;

    p {
      line-height: 40px;
      font-size: 16px;
    }
  }
}

.number-field {
  // Reset to large size
  --spectrum-fieldlabel-text-size: var(
    --spectrum-global-dimension-font-size-100,
    14px
  );
  --spectrum-fieldlabel-text-line-height: var(--spectrum-line-height-100);
  --spectrum-textfield-height: var(--spectrum-component-height-100, 32px);
  --spectrum-textfield-text-size: var(
    --spectrum-global-dimension-font-size-100,
    14px
  );

  --system-spectrum-textfield-border-color: var(
    --spectrum-global-color-gray-300
  );

  label {
    text-transform: capitalize;
  }
  input {
    padding: 0 2px;
    text-align: center;
    --spectrum-textfield-border-color: var(--spectrum-global-color-gray-300);
  }

  input[aria-invalid='true'] {
    padding-right: 2px !important;
    border: none !important;
  }

  input[aria-invalid='true']:focus {
    padding-right: 2px !important;
    border: none !important;
  }

  input[aria-invalid='true']:outline {
    padding-right: 2px !important;
    border: none !important;
  }

  input[aria-invalid='true'] + svg {
    display: none;
  }
}

@import 'styles/variables';

.root {
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.fullwidth {
  width: 100%;
}

.dialog {
  height: 124px;
  width: 350px;
  padding: 0.5em 2em;
  display: flex;
  flex-direction: column;
}

.progress {
  margin-bottom: 1em;
}

.button {
  display: flex;
  justify-content: flex-end;
}